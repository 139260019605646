<template>
  <div>
    <!-- Modal 1 -->
    <div v-if="isModal1Visible" class="modal-overlay" @click="closeModal(1)">
      <div class="modal-content" @click.stop>

        <div class="wrapLoader" v-if="showDeleteMapLoader">
          <span class="loader" />
          <p class="delMapLoaderTxt">Realizando deleção...</p>
        </div>

        <div class="wrapDelMapInfo" v-else>
          <img src="@/assets/icons/maps/warningModal.png" class="warningImg" />
          <p class="modalText">{{ fullTxtM1 }}</p>
          <div class="wrapBtnsModal">
            <button @click="closeModal(1)" class="modalBtn" style="background-color: #FF2A2A;">
              Não
            </button>
            <button @click="handleDeleteMap" class="modalBtn" style="background-color: #4375EF;">
              Sim
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal 2 -->
    <div v-if="isModal2Visible" class="modal-overlay" @click="!showEditMapLoader && handleOutsideClick(2)">
      <div class="modal-content" @click.stop>
        <div class="titleContainerModal">
          <img class="titleBackgoundModal" src="@/assets/icons/titleBackgound.svg" />
          <div class="titleTextModal">
            Editar Informações do mapa
          </div>
        </div>

        <input type="text" v-model="updateMapImgName" id="newNameInput" class="modalInput" ref="nameInput"
          @focus="moveCursorToEnd" :disabled="showEditMapLoader" />
        <p class="modalMapErr" v-if="showHasEmptyName">Nome não pode ser vazio</p>
        <p class="modalMapErr" v-if="showHasSameNameEdit">Nome já existe</p>
        <div class="wrapInputFile">
          <div class="fileNameDiv">
            <p>{{ fileName ? fileName : 'Inserir Imagem' }}</p>
          </div>
          <label for="inputMapFile" class="inputFileBtn"
            :style="showEditMapLoader ? 'pointer-events: none; opacity: 0.6;' : ''">
            Buscar
          </label>
          <input type="file" @change="handleFileUpload" id="inputMapFile" style="display: none;" accept="image/*"
            :disabled="showEditMapLoader" />
        </div>

        <div class="wrapLoader" v-if="showEditMapLoader">
          <span class="loader" />
        </div>

        <div class="wrapBtnsModal" style="width: 100%; margin-top: 10px;" v-else>
          <button @click="closeModal(2)" class="modalBtn" style="background-color: #FF2A2A;"
            :disabled="showEditMapLoader">
            Cancelar
          </button>
          <button @click="handleEditMap" class="modalBtn" style="background-color: #4375EF;"
            :disabled="showEditMapLoader || showHasEmptyName || showHasSameNameEdit">
            Salvar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal 3 -->
    <div v-if="isModal3Visible" class="modal-overlay" @click="closeModal(3)">
      <div class="modal-content" @click.stop>
        <img src="@/assets/icons/maps/warningModal.png" class="warningImg" />
        <p class="modalText">{{ fullTxtM3 }}</p>
        <div class="wrapBtnsModal">
          <button @click="closeModal(3)" class="modalBtn" style="background-color: #FF2A2A;">
            Não
          </button>
          <button @click="handleConfirmAction(3)" class="modalBtn" style="background-color: #4375EF;">
            Sim
          </button>
        </div>
      </div>
    </div>

    <!-- Modal 4 -->
    <div v-if="isModal4Visible" class="modal-overlay" @click="!showAddMapLoader && handleOutsideClick(4)">
      <div class="modal-content" @click.stop>
        <div class="titleContainerModal">
          <img class="titleBackgoundModal" src="@/assets/icons/titleBackgound.svg" />
          <div class="titleTextModal">
            Adicionar mapa
          </div>
        </div>

        <input type="text" v-model="newNameVal" id="newNameInput" placeholder="Inserir novo nome" class="modalInput"
          accept="image/*" :disabled="showAddMapLoader" />
        <p class="modalMapErr" v-if="showHasSameName">Nome já existe</p>

        <div class="wrapInputFile" :style="showAddMapLoader ? 'opacity: 0.6;' : ''">
          <div class="fileNameDiv" @click="triggerFileInput">
            <p>{{ fileName || 'Inserir Imagem' }}</p>
          </div>
          <label for="inputMapFile" class="inputFileBtn"
            :style="showAddMapLoader ? 'pointer-events: none; opacity: 0.6;' : ''">
            Buscar
          </label>
          <input type="file" @change="handleFileUpload" id="inputMapFile" style="display: none;" accept="image/*"
            :disabled="showAddMapLoader" />
        </div>
        <p class="modalMapErr" v-if="showAddMapErr">É necessário inserir uma imagem</p>

        <div class="wrapLoader" v-if="showAddMapLoader">
          <span class="loader" />
        </div>

        <div class="wrapBtnsModal" style="width: 100%; margin-top: 10px;" v-else>
          <button @click="closeModal(4)" class="modalBtn" style="background-color: #FF2A2A;"
            :disabled="showAddMapLoader">
            Cancelar
          </button>
          <button @click="handleAddMap" class="modalBtn" style="background-color: #4375EF;"
            :disabled="showAddMapLoader || showHasSameName || showAddMapErr">
            Salvar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal 5 -->
    <div v-if="isModal5Visible" class="modal-overlay" @click="closeModal(5)">
      <div class="modal-content" style="gap: 5px;" @click.stop>
        <div class="titleContainerModal">
          <img class="titleBackgoundModal" src="@/assets/icons/titleBackgound.svg" />
          <div class="titleTextModal">
            Criar novo perfil
          </div>
        </div>

        <input type="text" v-model="newNameVal" id="newNameInput" placeholder="Inserir nome do novo perfil"
          class="modalInput" :disabled="showCreateProfileLoader" />
        <p class="modalMapErr" v-if="showHasProfileName" style="margin-top: 2px;">Não pode ser vazio</p>

        <div class="wrapLoader" v-if="showCreateProfileLoader">
          <span class="loader" />
        </div>

        <div class="wrapBtnsModal" style="width: 100%; margin-top: 10px;" v-else>
          <button @click="closeModal(5)" class="modalBtn" style="background-color: #FF2A2A;"
            :disabled="showCreateProfileLoader">
            Cancelar
          </button>
          <button @click="createMapProfile()" class="modalBtn" style="background-color: #4375EF;"
            :disabled="showCreateProfileLoader || showHasProfileName">
            Salvar
          </button>
        </div>
      </div>
    </div>

    <!-- Modal 6 -->
    <div v-if="isModal6Visible" class="modal-overlay" @click="closeModal(6)">
      <div class="modal-content" @click.stop>
        <img src="@/assets/icons/maps/warningModal.png" class="warningImg" />
        <p class="modalText">{{ fullTxtM6 }}</p>
        <div class="wrapBtnsModal">
          <button @click="closeModal(6)" class="modalBtn" style="background-color: #FF2A2A;">
            Não
          </button>
          <button @click="handleConfirmAction(6)" class="modalBtn" style="background-color: #4375EF;">
            Sim
          </button>
        </div>
      </div>
    </div>

    <!-- Modal 7 -->
    <div v-if="isModal7Visible" class="modal-overlay" @click="closeModal(7)">
      <div class="modal-content" @click.stop>
        <img src="@/assets/icons/maps/warningModal.png" class="warningImg" />
        <p class="modalText">{{ modalContent7 }}</p>
        <div class="wrapBtnsModal">
          <button @click="closeModal(7)" class="modalBtn" style="background-color: #FF2A2A;">
            Não
          </button>
          <button @click="handleConfirmAction(7)" class="modalBtn" style="background-color: #4375EF;">
            Sim
          </button>
        </div>
      </div>
    </div>

    <!-- Modal 8 -->
    <div v-if="isModal8Visible" class="modal-overlay" @click="closeModal(8)">
      <div class="modal-content" @click.stop>
        <img src="@/assets/icons/maps/warningModal.png" class="warningImg" />
        <p class="modalText">{{ modalContent8 }}</p>

        <button @click="closeModal(8)" class="modalBtn" style="background-color: #4375EF;">
          OK
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import MapProfilesControl from '@/sys/Control/MapProfiles/MapProfilesControl';

export default {
  name: 'MapModals',
  data() {
    return {
      isModal1Visible: false,
      isModal2Visible: false,
      isModal3Visible: false,
      isModal4Visible: false,
      isModal5Visible: false,
      isModal6Visible: false,
      isModal7Visible: false,
      isModal8Visible: false,
      modalContent1: '',
      modalContent2: '',
      modalContent3: '',
      modalContent4: '',
      modalContent5: '',
      modalContent6: '',
      modalContent7: '',
      modalContent8: '',
      newNameVal: '',
      newMapFile: null,
      fileName: '',
      base64MapToAdd: '',
      inputFileName: '',
      updateMapImgName: MapProfilesControl.currentMap?.name,
      showAddMapErr: false,
      showAddMapLoader: false,
      showEditMapLoader: false,
      showDeleteMapLoader: false,
      showCreateProfileLoader: false,
      showHasSameName: false,
      showHasEmptyName: false,
      showHasSameNameEdit: false,
      showHasProfileName: false
    };
  },
  methods: {
    showModal(modalNumber, content, confirmAction) {
      this[`isModal${modalNumber}Visible`] = true;
      this[`modalContent${modalNumber}`] = content;
      const confirmActionKey = 'confirmAction' + String(modalNumber);
      this[confirmActionKey] = confirmAction;
    },
    closeModal(modalNumber) {
      this[`isModal${modalNumber}Visible`] = false;
      // Optionally reset the confirmAction
      this[`confirmAction${modalNumber}`] = null;
      // Reset form fields if necessary
      if (modalNumber === 2 || modalNumber === 4) {
        this.showHasSameName = false;
        this.newNameVal = '';
        this.newMapFile = null;
        this.fileName = '';
        this.updateMapImgName = MapProfilesControl.currentMap?.name;
      }

      if (modalNumber === 5) {
        this.showCreateProfileLoader = false;
        this.newNameVal = '';
      }
    },
    async createMapProfile() {
      let newProfileName = this.newNameVal.trim();

      if (newProfileName === '') {
        this.showHasProfileName = true;
        return
      } else {
        this.showCreateProfileLoader = true;
        this.showHasProfileName = false;
      }

      try {
        await MapProfilesControl.createMapProfile({ profileName: newProfileName });
        this.closeModal(5);
      } catch (error) {
        console.error('Error creating profile:', error);
        // You might want to show an error message to the user here
      } finally {
        this.showCreateProfileLoader = false;
      }
    },
    handleConfirmAction(modalNumber) {
      const confirmActionKey = 'confirmAction' + String(modalNumber);
      const confirmAction = this[confirmActionKey];

      if (confirmAction && typeof confirmAction === 'function') {
        confirmAction();
      } else {
        console.log('confirmAction is not a function or is undefined');
      }
      this.closeModal(modalNumber);
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      this.inputFileName = file.name;
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

      if (!file) return;

      if (!allowedTypes.includes(file.type)) {
        console.log('Por favor, selecione apenas arquivos de imagem nos formatos: JPG, JPEG, PNG ou WEBP');
        event.target.value = '';
        return;
      }

      try {
        const base64 = await this.convertToBase64(file);
        console.log('Original file object:', file.name);
        this.fileName = file.name;

        // Only set newNameVal if it's empty (user hasn't entered anything)
        if (!this.newNameVal.trim()) {
          // Tratamento especial para imagens do WhatsApp
          let suggestedName = file.name.replace(/\.[^/.]+$/, ""); // Remove a extensão

          // Verifica se é uma imagem do WhatsApp com padrão de data/hora
          if (/WhatsApp Image \d{4}-\d{2}-\d{2} at \d{2}\.\d{2}\.\d{2}/.test(suggestedName)) {
            suggestedName = "WhatsApp Image";
          }

          this.newNameVal = suggestedName;
        }
        
        this.imageData = base64;

        event.target.value = '';
        this.base64MapToAdd = base64;
      } catch (error) {
        console.error('Error processing image:', error);
      }
    },
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
      });
    },
    async handleAddMap() {
      if (this.base64MapToAdd === '') {
        this.showAddMapErr = true;
        return;
      } else {
        this.showAddMapLoader = true;
      }

      const resizedImage = await this.resizeImage(this.base64MapToAdd, 1920, 1080);

      const mapName = this.newNameVal.trim() === "" ?
        this.inputFileName.trim() : this.newNameVal.trim();

      const data = {
        profileId: MapProfilesControl.currentProfile._id,
        map: {
          name: mapName,
          imgBase64: resizedImage
        }
      };

      try {
        await MapProfilesControl.addMapToProfile(data);
      } catch (error) {
        console.log('Erro ao salvar mapa: ', error);
      }
      this.inputFileName = '';
      this.base64MapToAdd = '';
    },
    resizeImage(imgBase64, targetWidth, targetHeight) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

          // Detect original image format from base64
          const originalFormat = imgBase64.split(';')[0].split('/')[1];

          // Choose format and quality based on original
          let quality = 0.7;
          let outputFormat;

          switch (originalFormat) {
            case 'jpeg':
            case 'jpg':
              outputFormat = 'image/jpeg';
              break;
            case 'png':
              outputFormat = 'image/png';
              quality = 1; // PNG is lossless, quality parameter is ignored
              break;
            case 'webp':
              outputFormat = 'image/webp';
              break;
            default:
              outputFormat = 'image/jpeg'; // Default to JPEG for unknown formats
          }

          const resizedDataUrl = canvas.toDataURL(outputFormat, quality);

          const resizedImg = new Image();
          resizedImg.onload = () => {
            console.log(`Original format: ${originalFormat}`);
            console.log(`Output format: ${outputFormat}`);
            console.log(`Resized image dimensions: ${resizedImg.width}x${resizedImg.height}`);
            fetch(resizedDataUrl)
              .then(res => res.blob())
              .then(blob => {
                console.log(`Resized image size: ${(blob.size / 1024).toFixed(2)} KB`);
              });
          };
          resizedImg.src = resizedDataUrl;
          resolve(resizedDataUrl);
        };
        img.src = imgBase64;
      });
    },
    async handleDeleteMap() {
      this.showDeleteMapLoader = true;

      const currentMapId = MapProfilesControl.currentMap._id
      const currentProfileId = MapProfilesControl.currentProfile._id

      const data = {
        profileId: currentProfileId,
        mapId: currentMapId
      }

      await MapProfilesControl.removeMapFromProfile(data)
    },
    async handleEditMap() {
      if (this.base64MapToAdd === '' && this.updateMapImgName === MapProfilesControl.currentMap.name) {
        this.closeModalTwo()
      } else {
        if (this.updateMapImgName === '') {
          this.showHasEmptyName = true;
        }
        const newData = {
          mapId: MapProfilesControl.currentMap._id,
          profileId: MapProfilesControl.currentProfile._id,
          imgBase64: this.base64MapToAdd ? this.base64MapToAdd : null,
          name: this.updateMapImgName ? this.updateMapImgName : null,
        }
        this.showEditMapLoader = true;
        await MapProfilesControl.editCurrentMap(newData)
      }
    },
    updatePlaceHolder() {
      this.updateMapImgName = MapProfilesControl.currentMap?.name
    },
    moveCursorToEnd() {
      this.$nextTick(() => {
        const input = this.$refs.nameInput;
        const length = input.value.length;
        input.setSelectionRange(length, length);
      });
    },
    closeModalFour() {
      this.showAddMapLoader = false;
      this.closeModal(4);
    },
    closeModalTwo() {
      this.closeModal(2);
      this.showEditMapLoader = false;
    },
    closeModalOne() {
      this.closeModal(1);
      this.showDeleteMapLoader = false;
    },
    handleOutsideClick(modalNumber) {
      this.base64MapToAdd = '';
      this.showAddMapLoader = false;
      this.showHasSameName = false;
      this.showAddMapErr = false;
      this.closeModal(modalNumber);
    },
    triggerFileInput() {
      // Only trigger if not disabled
      if (!this.showAddMapLoader) {
        document.getElementById('inputMapFile').click();
      }
    },
    setupModal2KeyListener() {
      document.addEventListener('keydown', this.handleModal2KeyPress);
    },
    removeModal2KeyListener() {
      document.removeEventListener('keydown', this.handleModal2KeyPress);
    },
    handleModal2KeyPress(event) {
      if (!this.isModal2Visible || this.showEditMapLoader) return;

      if (event.key === 'Escape') {
        this.closeModal(2);
      } else if (event.key === 'Enter' && !this.showHasEmptyName && !this.showHasSameNameEdit) {
        this.handleEditMap();
      }
    },
    setupModal4KeyListener() {
      document.addEventListener('keydown', this.handleModal4KeyPress);
    },
    removeModal4KeyListener() {
      document.removeEventListener('keydown', this.handleModal4KeyPress);
    },
    handleModal4KeyPress(event) {
      if (!this.isModal4Visible || this.showAddMapLoader) return;

      if (event.key === 'Escape') {
        this.showAddMapErr = false;
        this.closeModal(4);
      } else if (event.key === 'Enter' && !this.showAddMapErr && !this.showHasSameName) {
        this.handleAddMap();
      }
    },
    setupModal5KeyListener() {
      document.addEventListener('keydown', this.handleModal5KeyPress);
    },
    removeModal5KeyListener() {
      document.removeEventListener('keydown', this.handleModal5KeyPress);
    },
    handleModal5KeyPress(event) {
      if (!this.isModal5Visible) return;

      if (event.key === 'Escape') {
        this.showHasProfileName = false;
        this.closeModal(5);
      } else if (event.key === 'Enter' && !this.showHasProfileName && !this.showCreateProfileLoader) {
        this.createMapProfile();
      }
    },
    setupModal3KeyListener() {
      document.addEventListener('keydown', this.handleModal3KeyPress);
    },
    removeModal3KeyListener() {
      document.removeEventListener('keydown', this.handleModal3KeyPress);
    },
    handleModal3KeyPress(event) {
      if (!this.isModal3Visible) return;

      if (event.key === 'Escape') {
        this.closeModal(3);
      } else if (event.key === 'Enter' && !this.showHasProfileName && !this.showCreateProfileLoader) {
        this.handleConfirmAction(3);
      }
    },
    setupModal1KeyListener() {
      document.addEventListener('keydown', this.handleModal1KeyPress);
    },
    removeModal1KeyListener() {
      document.removeEventListener('keydown', this.handleModal1KeyPress);
    },
    handleModal1KeyPress(event) {
      if (!this.isModal1Visible) return;

      if (event.key === 'Escape') {
        this.showDeleteMapLoader = false;
        this.closeModal(1);
      } else if (event.key === 'Enter' && !this.showDeleteMapLoader && !this.showCreateProfileLoader) {
        this.handleDeleteMap();
      }
    },
  },
  computed: {
    fullTxtM1() {
      return `Deseja realmente excluir o mapa "${this.modalContent1}"?`;
    },
    fullTxtM2() {
      return `Deseja realmente CONFIGURAR A PARADA "${this.modalContent1}"?`;
    },
    fullTxtM3() {
      return `Deseja realmente remover todos dispositivos do mapa "${this.modalContent3}"?`;
    },
    fullTxtM6() {
      return `Deseja realmente excluir o perfil "${this.modalContent6}"?`;
    }
  },
  created() {
    MapProfilesControl.addListenerChangedProfileMap(this.updatePlaceHolder);
    MapProfilesControl.addListenerChangedListOfProfile(this.closeModalFour);
    MapProfilesControl.addListenerChangedListOfProfile(this.closeModalTwo);
    MapProfilesControl.addListenerChangedListOfProfile(this.closeModalOne);
  },
  beforeDestroy() {
    MapProfilesControl.removeListenerChangedProfileMap(this.updatePlaceHolder);
    MapProfilesControl.removeListenerChangedListOfProfile(this.closeModalFour);
    MapProfilesControl.removeListenerChangedListOfProfile(this.closeModalTwo);
    MapProfilesControl.removeListenerChangedListOfProfile(this.closeModalOne);
  },
  watch: {
    base64MapToAdd: {
      immediate: true,
      handler(newVal) {
        if (newVal !== "") {
          this.showAddMapErr = false
        }
      }
    },
    /* Previne a inserção de nomes iguais */
    newNameVal: {
      immediate: true,
      handler(newVal) {
        const trimmedVal = newVal.trim();
        if(trimmedVal != ''){
          this.showHasProfileName = false;
        }
      }
    },
    updateMapImgName: {
      immediate: true,
      handler(newVal) {
        // Add null check before trying to use trim()
        if (!newVal) {
          this.showHasEmptyName = true;
          this.showHasSameNameEdit = false;
          return;
        }
        
        const trimmedVal = newVal.trim();
        if (trimmedVal === '') {
          this.showHasEmptyName = true;
        } else {
          this.showHasEmptyName = false;
        }

        // Check if name exists but exclude the current map from the comparison
        const nameExists = MapProfilesControl.currentProfile.maps.some(map =>
          map.name.trim() === trimmedVal && map._id !== MapProfilesControl.currentMap._id
        );

        this.showHasSameNameEdit = nameExists;
      }
    },
    isModal2Visible(newVal) {
      if (newVal) {
        this.setupModal2KeyListener();
      } else {
        this.removeModal2KeyListener();
      }
    },
    isModal4Visible(newVal) {
      if (newVal) {
        this.setupModal4KeyListener();
      } else {
        this.removeModal4KeyListener();
      }
    },
    isModal5Visible(newVal) {
      if (newVal) {
        this.setupModal5KeyListener();
      } else {
        this.removeModal5KeyListener();
      }
    },
    isModal3Visible(newVal) {
      if (newVal) {
        this.setupModal3KeyListener();
      } else {
        this.removeModal3KeyListener();
      }
    },
    isModal1Visible(newVal) {
      if (newVal) {
        this.setupModal1KeyListener();
      } else {
        this.removeModal1KeyListener();
      }
    },
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  display: flex;
  flex-direction: column;
  background-color: #292D32;
  padding: 20px;
  border-radius: 10px;
  width: 25%;
  min-width: 300px;
  gap: 15px;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.warningImg {
  width: 30%;
}

.modalText {
  color: #FFCC00;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  width: 80%;
}

.wrapBtnsModal {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.modalBtn {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  border-radius: 6px;
  padding: 10px 40px 10px 40px;
  cursor: pointer;
}

.titleContainerModal {
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  float: left;
}

.titleBackgoundModal {
  width: 100%;
}

.titleTextModal {
  position: absolute;
  width: 90%;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-weight: regular;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
}

.inputFileBtn {
  border-radius: 0px 6px 6px 0px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 8px 13px 8px 13px;
  cursor: pointer;
  background-color: #4375EF;
  margin-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileNameDiv {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  box-sizing: border-box;
  height: fit-content;
  border-radius: 6px 0px 0px 06px;
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  color: #78768d;
  font-size: 12px;
  background-color: #1E2227;
  border: none;
  outline: none;
  line-height: 18px;;
}

.wrapInputFile {
  display: flex;
  width: 100%;
  cursor: pointer;
}

.modalInput {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  box-sizing: border-box;
  height: 30px;
  border-radius: 6px;
  padding: 8px;
  font-family: Arial, Helvetica, sans-serif;
  color: #78768d;
  font-size: 12px;
  background-color: #1E2227;
  border: none;
  outline: none;
}

.modalMapErr {
  color: #FF2A2A;
  margin-top: -10px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  align-self: flex-start;
}

.loader {
  width: 58px;
  height: 58px;
  border: 5px solid #1E2227;
  border-bottom-color: #4375EF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.wrapLoader {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.delMapLoaderTxt {
  font-family: Arial, Helvetica, sans-serif;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: bold;
}

.wrapDelMapInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.modalBtn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.modalInput:disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: #f5f5f5;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media(max-width: 1200px) {
  .modalBtn {
    padding: 10px 25px 10px 25px;
  }
}
</style>
